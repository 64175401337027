import styled from 'styled-components';
import { keyframes } from 'styled-components';

let bgColor = '#fff';
let bgLightGray = '#f5f1ed';
let borderColor = '#e6e8eb';
let colorPrimary = '#16161a';
let colorSecondary = '#505052';

export const Container = styled.div`
  max-width: 1021px;
  margin: 0 auto;
`;

export const H1 = styled.h1`
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: ${colorPrimary};
`;

export const H2 = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  color: ${colorPrimary};
`;

export const H3B = styled.h3`
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: ${colorPrimary};
`;
export const P = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${colorSecondary};
  > a {
    text-decoration: none;
    font-weight: 600;
    color: ${colorSecondary};
  }
  > span {
    font-weight: 600;
  }
`;

export const Ol = styled.ol`
  font-size: 16px;
  line-height: 24px;
  color: ${colorSecondary};
  padding: 1em;
`;

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  margin: 0 auto;
  background-color: ${bgColor};
`;
export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.25em 1.25em 1em 1.25em;
  background-color: ${bgColor};
  max-width: 1021px;
  margin: 0 auto;
`;

const slide = keyframes`
    from { height: 0 }
    to { height: 188px;}
`;

export const MenuContainer = styled.div`
  background-color: ${bgColor};
  max-width: 1021px;
  margin: 0 auto;
  animation: ${slide} 0.25s ease 0s forwards;
`;

export const NavMenu = styled.div`
  display: flex;
  line-height: 39px;
  > .nev-items {
    display: flex;
    margin: 0 30px;
    font-size: 14px;
    text-decoration: none;
    color: ${colorSecondary};
    font-weight: 400;
    margin-top: -5px;
    cursor: pointer;
    > a {
      text-decoration: none;
      color: ${colorSecondary};
    }
  }
`;

export const MenuList = styled.div`
  font-size: 14.5px;
  padding: 1.5em;
  display: block;
  color: ${colorSecondary};
  border-bottom: 2px solid ${borderColor};
  background-color: ${bgColor};
  animation: 0.5s ease 0s forwards;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const LoginBtn = styled.div`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  > a {
    text-decoration: none;
    color: ${colorSecondary};
  }
`;
export const SignupBtn = styled.div`
  font-weight: 500;
  width: 8em;
  height: 40px;
  background: ${bgLightGray};
  border-radius: 12px;
  margin-left: 3em;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeroContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

export const BannerContant = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5em 1.5em 0 1.5em;
  div > .banner-title {
    font-family: Inter, sans-serif;
    font-weight: 700;
    max-width: 900px;
    margin: 0 0 16px 0;
    line-height: 55px;
    font-size: 50px;
    letter-spacing: -0.015em;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(130deg, rgb(255 166 0) 0%, rgb(255, 95, 88) 30%, #4044b7 88%, #6151a2 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  div > .bubbles {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  div > .banner-subtitle {
    max-width: 634px;
    font-size: 21px;
    color: #505052;
    line-height: 35px;
    font-family: Inter, sans-serif;
    font-weight: 300;
    margin: 1em auto 2.5em auto;
  }
  div > .request-btn {
    min-width: 200px;
    font-family: Inter, sans-serif;
    outline: none;
    background: linear-gradient(180deg, #2e2e36 0%, #16161a 100%);
    box-shadow: -35px 55px 100px rgba(192, 152, 104, 0.45);

    cursor: pointer;
    border: none;
    text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-size: 1.1rem;
    padding: 1.2em 2.6rem;
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    :hover {
      background: #42424d;
    }
  }
  div > .label-style {
    margin-top: 2.6em;
    color: ${colorSecondary};
    font-size: 14px;
  }
  div > .line {
    width: 100%;
    height: 11px;
    position: relative;
    > img {
      position: absolute;
      right: 5em;
      top: -15px;
    }
  }
  @media only screen and (max-width: 640px) {
    .bubbles {
      display: none;
    }
    .color {
      display: none;
    }
    div > .request-btn {
      margin-top: 1em;
      width: 100%;
      border-radius: 10px;
    }
  }
`;

export const BannerBg = styled.div`
  position: relative;
  > .color {
    position: absolute;
    width: 450px;
    bottom: 40px;
    right: -11px;
  }
  > .preview-img {
    width: 100%;
    margin-top: 15px;
  }
`;

export const FeaturesContainer = styled.div`
  text-align: center;
  padding-top: 5em;
  > div {
    max-width: 1021px;
    margin: 0 auto;
    padding: 1.5em;
  }
  > div > .features-title {
    color: ${colorPrimary};
    max-width: 1170px;
    font-size: 34px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.45px;
    text-align: start;
    > span {
      font-weight: 700;
    }
  }
  .features-subtitle {
    font-size: 22px;
    color: ${colorSecondary};
    margin: 15px 0;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 36px;
    text-align: start;
  }
  .features-card-container {
    display: flex;
    flex-flow: row wrap;
    max-width: 1021px;
    margin: 0 auto;
    margin-top: 3em;
  }
  .features-card-item {
    box-shadow: 0px 53px 29px rgba(0, 0, 0, 0.025);
    flex: 1 0 25%;
    margin: 1em;
    min-height: 307px;
    min-width: 19rem;
    color: ${colorSecondary};
    text-align: initial;
    border: 1px solid ${borderColor};
    padding: 2em;
    margin-left: 0;
    margin-bottom: 0;
    border-radius: 10px;
    > .card-title {
      font-size: 21px;
      color: ${colorPrimary};
      margin: 15px 0;
      font-family: Inter, sans-serif;
      font-weight: 500;
      line-height: 32px;
    }
    > .card-content {
      font-size: 16px;
      color: ${colorSecondary};
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 26px;
    }
    .tag {
      background-color: ${bgLightGray};
      padding: 5px;
      width: max-content;
      border-radius: 5px;
      font-size: 13px;
      color: ${colorSecondary};
      font-weight: 600;
      margin-top: 7px;
    }
  }
  .features-card-item:nth-child(3n + 3) {
    margin-right: 0;
  }
`;

export const CenterCardContainer = styled.div`
  max-width: 975px;
  background: linear-gradient(212.75deg, rgb(248, 245, 240) 47.88%, #fff 94.72%);
  border: 1px solid $border-color;
  box-shadow: inset 158px 50px 100px rgba(250, 245, 240, 0.25);
  border-radius: 25px;
  margin: 5em auto !important;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 3em;
  > .big-card-title {
    color: $color-primary;
    max-width: 1170px;
    margin: 0 auto;
    text-align: center;
    font-size: 25px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: -0.35px;
  }

  > .big-card-subtitle {
    font-size: 16px;
    color: ${colorSecondary};
    font-family: Inter, sans-serif;
    font-weight: 300;
    line-height: 30px;
    text-decoration: none;
    text-align: center;
    > span {
      font-weight: 600;
    }
  }
`;

export const FooterContainer = styled.div`
  max-width: 1021px;
  margin: 0 auto;
  padding: 30px 1em;
  color: $color-secondary;
  font-family: Inter, sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  > .legal {
    display: flex;
    > a {
      margin-left: 1em;
      cursor: pointer;
      text-decoration: none;
      color: ${colorSecondary};
    }
    > p {
      margin: 0;
    }
    > span {
      color: ${borderColor};
      margin: 0 0.5em;
    }
  }
`;

export const WhiteBtn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 5px;
  flex-shrink: 0;
  font-weight: 500;
  line-height: normal;
  font-size: 13px;
  min-width: 32px;
  padding: 0px 21px;
  border: 1px solid rgb(223, 225, 228);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px;
  background: rgb(255, 255, 255);
  color: rgb(60, 65, 73);
  height: 36px;
  cursor: pointer;
  outline: none;
  > a {
    text-decoration: none;
    color: ${colorSecondary};
  }
  :active {
    transform: scale(0.99);
  }
  :hover {
    background: ${bgLightGray};
    border-color: rgb(201, 203, 205);
  }
  :focus {
    outline: none;
  }
`;

export const Card = styled.div`
  background: rgb(248, 245, 240);
  padding: 2em;
  border-radius: 10px;
`;

export const BottomBanner = styled.div`
  text-align: center;
  padding: 8em 8em 6em 8em;
`;
