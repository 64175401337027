import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Logo from '../static/logo.svg';
import MenuIco from '../static/menu.svg';

import { HeaderContainer, HeaderSection, MenuContainer, NavMenu, MenuList, LoginBtn, WhiteBtn } from '../styles/styles';

const Header = () => {
  const [smScreen, setSmScreen] = useState(false);
  const [smScreenMenu, setSmScreenMenu] = useState(false);

  useEffect(() => {
    const cat = localStorage.getItem('CognitoIdentityServiceProvider.7l52luokv4mrbulmr8pqosomcd.a24e0c7e-4c73-45dc-a63d-ff03bcd078bd.accessToken');
    if (cat !== null) {
      window.location.pathname = '/app';
    }
  }, []);

  useEffect(() => {
    if (window.location.hash.includes('Already+found+an+entry+for+username')) {
      window.location.pathname = '/login';
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  const resize = () => {
    if (window.innerWidth < 768) {
      setSmScreen(true);
    } else {
      setSmScreen(false);
      setSmScreenMenu(false);
    }
  };

  let nevItems;
  if (smScreen) {
    nevItems = (
      <div onClick={() => setSmScreenMenu(!smScreenMenu)}>
        <img src={MenuIco} alt="menu-icon" />
      </div>
    );
  } else {
    nevItems = (
      <NavMenu>
        <div className="nev-items">
          <Link to="/features">Features</Link>
        </div>
        <div className="nev-items" style={{ marginRight: 0 }}>
          <LoginBtn>
            <a href="/login">Login</a>
          </LoginBtn>
          <WhiteBtn style={{ marginLeft: '4em' }}>
            <a href="/signup">Signup</a>
          </WhiteBtn>
        </div>
      </NavMenu>
    );
  }

  return (
    <HeaderContainer>
      <HeaderSection>
        <div>
          <Link to="/" aria-label="Design Lobby">
            <img src={Logo} alt="design-lobby" />
          </Link>
        </div>
        <div>{nevItems}</div>
      </HeaderSection>
      {smScreenMenu && (
        <MenuContainer>
          <MenuList>Features</MenuList>
          <MenuList>
            <LoginBtn>
              <a href="/login">Login</a>
            </LoginBtn>
            <WhiteBtn style={{ marginLeft: '4em' }}>
              <a href="/signup">Signup</a>
            </WhiteBtn>
          </MenuList>
        </MenuContainer>
      )}
    </HeaderContainer>
  );
};

export default Header;
