import React from 'react';
import { FooterContainer } from '../styles/styles';
import { Link } from 'gatsby';
import Logo from '../static/logo-s.svg';

const Footer = () => {
  return (
    <FooterContainer>
      <div style={{ display: 'flex' }}>
        <Link to="/" aria-label="Design Lobby">
          <img src={Logo} alt="Design-lobby logo" />
        </Link>
        <p style={{ marginTop: '6px', marginLeft: '12px' }}>
          Design Lobby - Made with <span className="heart">♥</span> in 2020.
        </p>
      </div>

      <div className="legal">
        <Link to="/privacy">Privacy</Link>
        <Link to="/terms">Terms and Conditions</Link>
        <span>|</span>
        <a href="https://twitter.com/DesignLobbyApp" target="_blank">
          🐦 Twitter
        </a>
        <a href="https://dribbble.com/designlobbyapp" target="_blank">
          🏀 Dribbble
        </a>
      </div>
    </FooterContainer>
  );
};

export default Footer;
